export interface UserInfo {
  ename?: string
  username?: string
  email?: string
  name?: string
  birthdate?: string
  gender?: string
  secondary_school?: string
  secondary_startdate?: string
  graduation_date?: string
  pen_number?: string
  medical_number?: string
  university?: string
  phone_number?: string
  qq_number?: string
  wechat_id?: string
  counsellor?: string
  custodian?: string
  general_manager?: string
  studypermit_date?: string
  passport_date?: string
  trv_date?: string
  role?: UserRole
}

export enum UserRole {
  Student = 'student',
  Parent = 'parent',
  Staff = 'staff',
  Admin = 'admin',
}

export type ProfileFieldData = {
  elabel: string
  clabel: string
  dataType: string
  canReadRoles?: UserRole[]
  canUpdateRoles?: UserRole[]
}

type ProfileFieldsDictionary = {
  [key: string]: ProfileFieldData
}

// Profile fields for STUDENT
// Default permissions:
// canUpdateRoles = [UserRole.Staff, UserRole.Admin]
// canReadRoles = [UserRole.Student, UserRole.Parent, UserRole.Staff, UserRole.Admin]
export const studentProfileFields: ProfileFieldsDictionary = {
  name: {
    elabel: 'Chinese Name',
    clabel: '全名',
    dataType: 'string',
    canUpdateRoles: [],
  },
  email: {
    elabel: 'Email',
    clabel: '电子邮箱',
    dataType: 'string',
    canUpdateRoles: [],
  },
  ename: {
    elabel: 'English Name',
    clabel: '英文名',
    dataType: 'string',
    canUpdateRoles: [],
  },
  gender: {
    elabel: 'Gender',
    clabel: '性别',
    dataType: 'string',
    canUpdateRoles: [],
  },
  secondary_school: {
    elabel: 'Secondary School',
    clabel: '就读学校',
    dataType: 'string',
  },
  birthdate: {
    elabel: 'Birthdate (YYYY-MM-DD)',
    clabel: '出生日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
  secondary_startdate: {
    elabel: 'Secondary School Start Date (YYYY-MM-DD)',
    clabel: '入读日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
  graduation_date: {
    elabel: 'Graduation Date (YYYY-MM-DD)',
    clabel: '毕业日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
  pen_number: {
    elabel: 'Personal Education Number (PEN)',
    clabel: 'PEN号码',
    dataType: 'string',
  },
  medical_number: {
    elabel: 'Medical Card Number',
    clabel: '医保卡号',
    dataType: 'string',
  },
  university: {
    elabel: 'Current University',
    clabel: '就读大学',
    dataType: 'string',
  },
  phone_number: {
    elabel: 'Phone Number',
    clabel: '电话号码',
    dataType: 'string',
    canUpdateRoles: [UserRole.Student, UserRole.Staff, UserRole.Admin],
  },
  qq_number: {
    elabel: 'QQ Number',
    clabel: 'QQ号码',
    dataType: 'string',
    canUpdateRoles: [UserRole.Student, UserRole.Staff, UserRole.Admin],
  },
  wechat_id: {
    elabel: 'WeChat ID',
    clabel: '微信号码',
    dataType: 'string',
    canUpdateRoles: [UserRole.Student, UserRole.Staff, UserRole.Admin],
  },
  general_manager: {
    elabel: 'General Manager',
    clabel: '全面负责人',
    dataType: 'string',
  },
  counsellor: {
    elabel: 'Counsellor',
    clabel: '学校辅导员 (Counsellor)',
    dataType: 'string',
  },
  custodian: {
    elabel: 'Custodian',
    clabel: '监护人',
    dataType: 'string',
  },
  studypermit_date: {
    elabel: 'Study Permit Expiration Date (YYYY-MM-DD)',
    clabel: '学签到期日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
  passport_date: {
    elabel: 'Passport Expiration Date (YYYY-MM-DD)',
    clabel: '护照到期日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
  trv_date: {
    elabel: 'Temporary Resident VISA Expiration Date (YYYY-MM-DD)',
    clabel: '入境签证（小签）到期日期 (YYYY-MM-DD)',
    dataType: 'string',
  },
}

// Set default permissions for reading and updating STUDENT profile fields
for (const fieldKey in studentProfileFields) {
  const field = studentProfileFields[fieldKey]
  if (!field.canReadRoles) {
    field.canReadRoles = [UserRole.Student, UserRole.Parent, UserRole.Staff, UserRole.Admin]
  }
  if (!field.canUpdateRoles) {
    field.canUpdateRoles = [UserRole.Staff, UserRole.Admin]
  }
}

export const secondarySchools = [
  'Semiahmoo Secondary School',
  'Elgin Park Secondary School',
  'Earl Marriott Secondary School',
  'Gleneagle Secondary School',
  'Pinetree Secondary School',
  'Port Moody Secondary School',
  'BC Christian Academy',
  'Royal Bridge High School',
]

// Profile fields for PARENT
// Default permissions:
// canUpdateRoles = [UserRole.Parent, UserRole.Staff, UserRole.Admin]
// canReadRoles = [UserRole.Student, UserRole.Parent, UserRole.Staff, UserRole.Admin]
export const parentProfileFields: ProfileFieldsDictionary = {
  name: {
    elabel: 'Chinese Name',
    clabel: '全名',
    dataType: 'string',
    canUpdateRoles: [],
  },
  email: {
    elabel: 'Email',
    clabel: '电子邮箱',
    dataType: 'string',
    canUpdateRoles: [],
  },
  relationship: {
    elabel: 'Relationship',
    clabel: '关系',
    dataType: 'string',
    canUpdateRoles: [],
  },
  phone_number: {
    elabel: 'Phone Number',
    clabel: '电话号码',
    dataType: 'string',
  },
  qq_number: {
    elabel: 'QQ Number',
    clabel: 'QQ号码',
    dataType: 'string',
  },
  wechat_id: {
    elabel: 'WeChat ID',
    clabel: '微信号码',
    dataType: 'string',
  },
}

// Set default permissions for reading and updating PARENT profile fields
for (const fieldKey in parentProfileFields) {
  const field = parentProfileFields[fieldKey]
  if (!field.canReadRoles) {
    field.canReadRoles = [UserRole.Student, UserRole.Parent, UserRole.Staff, UserRole.Admin]
  }
  if (!field.canUpdateRoles) {
    field.canUpdateRoles = [UserRole.Parent, UserRole.Staff, UserRole.Admin]
  }
}
